import api from "../../../assets/genericComponents/API";

const getAllSpeciality = async (data) => {
  const response = await api.get("api/speciality/getAllSpeciality", {
    params: data,
  });
  return response.data.data;
};

const getAllDoctors = async (data) => {
  const response = await api.get("api/doctor/getAllDoctors", {
    params: data,
  });
  return response.data.data;
};
const getAvailableDoctorSlots = async (data) => {
  const response = await api.get("api/appointment/getAvailableDoctorSlots", {
    params: data,
  });
  return response.data.data;
};

const getDoctorSlotsDates = async (data) => {
  const response = await api.get("api/appointment/getDoctorSlotsDates", {
    params: data,
  });
  return response.data.data;
};

const bookDoctorAppointment = async (data) => {
  const response = await api.post(
    `/api/appointment/bookDoctorAppointment`,
    data
  );
  return response.data;
};

const cancelDoctorAppointment = async (data) => {
  const response = await api.post(
    `/api/appointment/cancelDoctorAppointment`,
    data
  );
  return response.data;
};

const BookAppointmentServices = {
  getAllSpeciality,
  getAllDoctors,
  getAvailableDoctorSlots,
  bookDoctorAppointment,
  getDoctorSlotsDates,
  cancelDoctorAppointment,
};

export default BookAppointmentServices;

import api from "../../../assets/genericComponents/API";

const getPatientVisitTypeInvoices = async (data) => {
  const response = await api.get("/api/invoice/getPatientVisitTypeInvoices", {
    params: data,
  });
  return response.data.data;
};

const getPatientVisitTypeInvoices_V2 = async (data) => {
  const response = await api.get(
    "/api/invoice/getPatientVisitTypeInvoices_V2",
    {
      params: data,
    }
  );
  return response.data.data;
};

const billingServices = {
  getPatientVisitTypeInvoices,
  getPatientVisitTypeInvoices_V2,
};

export default billingServices;

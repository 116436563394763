import api from "../../../assets/genericComponents/API";

const getPatientTestsSummarizations = async (data) => {
  const response = await api.get("/api/patientTestsSummarizations/getAll", {
    params: data,
  });
  return response.data.data;
};
const getLabTestGraph = async (data) => {
  const response = await api.get(
    "/api/patientTestsSummarizations/getLabTestGraph",
    {
      params: data,
    }
  );
  return response.data.data;
};

const labTestServices = {
  getLabTestGraph,
  getPatientTestsSummarizations,
};

export default labTestServices;

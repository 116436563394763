import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import labTestServices from "./labTest.service";

const initialState = {
  isError: false,
  isSuccess: false,
  message: "",

  getPatientTestsSummarizations: [],
  getLabTestGraph: [],
  getPatientTestsSummarizationsLoading: false,
  getLabTestGraphLoading: false,
};

export const getPatientTestsSummarizationsAction = createAsyncThunk(
  "labTest/getPatientTestsSummarizationsAction",
  // async ({ finalData, moveToNext }, thunkAPI) => {
  async ({ finalData }, thunkAPI) => {
    try {
      const response = await labTestServices.getPatientTestsSummarizations(
        finalData
      );
      // if (moveToNext) {
      //   moveToNext(response?.[0]);
      // }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getLabTestGraphAction = createAsyncThunk(
  "labTest/getLabTestGraphAction",
  // async ({ finalData, moveToNext }, thunkAPI) => {
  async ({ finalData, moveToNext }, thunkAPI) => {
    try {
      const response = await labTestServices.getLabTestGraph(finalData);
      if (moveToNext) {
        moveToNext(response);
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const labTestSlice = createSlice({
  name: "labTest",
  initialState,
  reducers: {
    clearLabTest: (state) => {
      state.getPatientTestsSummarizations = [];
      state.getPatientTestsSummarizationsisLoading = false;
      state.isFailure = false;
      state.isSuccess = false;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getPatientTestsSummarizationsAction.pending, (state) => {
        state.getPatientTestsSummarizationsLoading = true;
      })
      .addCase(
        getPatientTestsSummarizationsAction.fulfilled,
        (state, action) => {
          state.getPatientTestsSummarizationsLoading = false;
          state.isSuccess = true;
          state.getPatientTestsSummarizations = action.payload;
        }
      )
      .addCase(
        getPatientTestsSummarizationsAction.rejected,
        (state, action) => {
          state.getPatientTestsSummarizationsLoading = false;
          state.isError = true;
          state.message = action.payload;
        }
      )

      .addCase(getLabTestGraphAction.pending, (state) => {
        state.getLabTestGraphLoading = true;
      })
      .addCase(getLabTestGraphAction.fulfilled, (state, action) => {
        state.getLabTestGraphLoading = false;
        state.isSuccess = true;
        state.getLabTestGraph = action.payload;
      })
      .addCase(getLabTestGraphAction.rejected, (state, action) => {
        state.getLabTestGraphLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { clearLabTest } = labTestSlice.actions;

export default labTestSlice.reducer;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import BookAppointmentServices from "./bookAppointment.service";

const initialState = {
  getAllDoctors: [],
  getAllSpeciality: [],
  getAvailableDoctorSlots: [],
  getDoctorSlotsDates: [],
  bookDoctorAppointment: [],
  cancelDoctorAppointment: [],
  isError: false,
  isLoading: false,
  getAllSpecialityisLoading: false,
  getAllDoctorsisLoading: false,
  getAvailableDoctorSlotsisLoading: false,
  getDoctorSlotsDatesisLoading: false,
  bookDoctorAppointmentisLoading: false,
  cancelDoctorAppointmentLoading: false,
  isSuccess: false,
  message: "",
};

export const getAllDoctorsAction = createAsyncThunk(
  "bookappointment/getAllDoctorsAction",
  async (data, thunkAPI) => {
    try {
      return await BookAppointmentServices.getAllDoctors(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllSpecialityAction = createAsyncThunk(
  "bookappointment/getAllSpecialityAction",
  async (data, thunkAPI) => {
    try {
      return await BookAppointmentServices.getAllSpeciality(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAvailableDoctorSlotsAction = createAsyncThunk(
  "bookappointment/getAvailableDoctorSlotsAction",
  async (data, thunkAPI) => {
    try {
      return await BookAppointmentServices.getAvailableDoctorSlots(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getDoctorSlotsDatesAction = createAsyncThunk(
  "bookappointment/getDoctorSlotsDatesAction",
  async ({ finalData, moveToNextApiCall }, thunkAPI) => {
    try {
      const response = await BookAppointmentServices.getDoctorSlotsDates(
        finalData
      );
      if (moveToNextApiCall) {
        moveToNextApiCall(response);
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const bookDoctorAppointmentAction = createAsyncThunk(
  "bookappointment/bookDoctorAppointmentAction",
  async ({ finalData, notification, movetoNext }, thunkAPI) => {
    try {
      const response = await BookAppointmentServices.bookDoctorAppointment(
        finalData
      );
      if (response.httpStatusCode === 200) {
        if (notification) {
          notification(response.message, "success");
        }
        if (movetoNext) {
          movetoNext();
        }
      } else {
        notification(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const cancelDoctorAppointmentAction = createAsyncThunk(
  "bookappointment/cancelDoctorAppointmentAction",
  async ({ finalData, notification, movetoNext }, thunkAPI) => {
    try {
      const response = await BookAppointmentServices.cancelDoctorAppointment(
        finalData
      );
      if (response.httpStatusCode === 200) {
        if (notification) {
          notification(response.message, "success");
        }
        if (movetoNext) {
          movetoNext();
        }
      } else {
        notification(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const bookappointmentSlice = createSlice({
  name: "bookappointment",
  initialState,
  reducers: {
    clearbookappointment: (state) => {
      state.getAllDoctors = [];
      state.getAllSpeciality = [];
      state.bookDoctorAppointment = [];
      state.getDoctorSlotsDates = [];
      state.getAvailableDoctorSlots = [];
      state.isLoading = false;
      state.isFailure = false;
      state.isSuccess = false;
      state.getAllSpecialityisLoading = false;
      state.getAllDoctorsisLoading = false;
      state.getAvailableDoctorSlotsisLoading = false;
      state.bookDoctorAppointmentisLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllDoctorsAction.pending, (state) => {
        state.getAllDoctorsisLoading = true;
      })
      .addCase(getAllDoctorsAction.fulfilled, (state, action) => {
        state.getAllDoctorsisLoading = false;
        state.isSuccess = true;
        state.getAllDoctors = action.payload;
      })
      .addCase(getAllDoctorsAction.rejected, (state, action) => {
        state.getAllDoctorsisLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getAvailableDoctorSlotsAction.pending, (state) => {
        state.getAvailableDoctorSlotsisLoading = true;
      })
      .addCase(getAvailableDoctorSlotsAction.fulfilled, (state, action) => {
        state.getAvailableDoctorSlotsisLoading = false;
        state.isSuccess = true;
        state.getAvailableDoctorSlots = action.payload;
      })
      .addCase(getAvailableDoctorSlotsAction.rejected, (state, action) => {
        state.getAvailableDoctorSlotsisLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(bookDoctorAppointmentAction.pending, (state) => {
        state.bookDoctorAppointmentisLoading = true;
      })
      .addCase(bookDoctorAppointmentAction.fulfilled, (state, action) => {
        state.bookDoctorAppointmentisLoading = false;
        state.isSuccess = true;
        state.bookDoctorAppointment = action.payload;
      })
      .addCase(bookDoctorAppointmentAction.rejected, (state, action) => {
        state.bookDoctorAppointmentisLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(cancelDoctorAppointmentAction.pending, (state) => {
        state.cancelDoctorAppointmentLoading = true;
      })
      .addCase(cancelDoctorAppointmentAction.fulfilled, (state, action) => {
        state.cancelDoctorAppointmentLoading = false;
        state.isSuccess = true;
        state.cancelDoctorAppointment = action.payload;
      })
      .addCase(cancelDoctorAppointmentAction.rejected, (state, action) => {
        state.cancelDoctorAppointmentLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getDoctorSlotsDatesAction.pending, (state) => {
        state.getDoctorSlotsDatesisLoading = true;
      })
      .addCase(getDoctorSlotsDatesAction.fulfilled, (state, action) => {
        state.getDoctorSlotsDatesisLoading = false;
        state.isSuccess = true;
        state.getDoctorSlotsDates = action.payload;
      })
      .addCase(getDoctorSlotsDatesAction.rejected, (state, action) => {
        state.getDoctorSlotsDatesisLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getAllSpecialityAction.pending, (state) => {
        state.getAllSpecialityisLoading = true;
      })
      .addCase(getAllSpecialityAction.fulfilled, (state, action) => {
        state.getAllSpecialityisLoading = false;
        state.isSuccess = true;
        state.getAllSpeciality = action.payload;
      })
      .addCase(getAllSpecialityAction.rejected, (state, action) => {
        state.getAllSpecialityisLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { clearbookappointment } = bookappointmentSlice.actions;

export default bookappointmentSlice.reducer;

import api from "../../../assets/genericComponents/API";

const getAllMedicalHistory = async (data) => {
  const response = await api.get("api/appointment/getAllMedicalHistory", {
    params: data,
  });
  return response.data;
};

const MedicalHistoryServices = {
  getAllMedicalHistory,
};

export default MedicalHistoryServices;

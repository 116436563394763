import React from "react";

const NoPage = React.lazy(() => import("./NoPageFound"));
const SignIn = React.lazy(() => import("./Authentication"));
const SignUp = React.lazy(() => import("./Authentication/SignUp"));
const ForgetPassword = React.lazy(() =>
  import("./Authentication/ForgetPassword")
);
const Dashboard = React.lazy(() => import("./Dashboard"));
const Appointments = React.lazy(() => import("./Patient/Appointments"));
const BookAppointments = React.lazy(() => import("./Patient/BookAppointment"));
const ConsultView = React.lazy(() =>
  import("./Patient/BookAppointment/consultView")
);
const Prescription = React.lazy(() => import("./Patient/Prescription"));
const Billing = React.lazy(() => import("./Patient/Billing"));
const Reports = React.lazy(() => import("./Patient/Reports"));
const Upload = React.lazy(() => import("./Patient/Upload"));
const executiveViewDetail = React.lazy(() =>
  import("./Patient/ExecutiveHealthCheckups/viewDetail")
);
const ExecutiveHealthCheckups = React.lazy(() =>
  import("./Patient/ExecutiveHealthCheckups")
);
const bookNowForm = React.lazy(() =>
  import("./Patient/ExecutiveHealthCheckups/bookNowForm")
);
const LandingPage = React.lazy(() => import("./LandingPage"));
const LabTest = React.lazy(() => import("./Patient/LabTest"));
const LabTestDetailView = React.lazy(() =>
  import("./Patient/LabTest/labTestDetailView")
);
const DocumentView = React.lazy(() => import("./Patient/DocumentView"));
const ResetPassword = React.lazy(() => import("./Patient/ResetPassword"));

const WEB_PAGES = {
  NOPAGE: NoPage,
  SIGN_IN: SignIn,
  SIGN_UP: SignUp,
  FORGET_PASSWORD: ForgetPassword,
  RESET_PASSWORD: ResetPassword,
  CONSULTVIEW: ConsultView,
  DASHBOARD: Dashboard,
  APPOINTMENTS: Appointments,
  BOOKAPPOINTMENTS: BookAppointments,
  PRESCRIPTION: Prescription,
  REPORTS: Reports,
  BILLING: Billing,
  UPLOAD: Upload,
  EXECUTIVEHEALTHCHECKUPS: ExecutiveHealthCheckups,
  EXECUTIVEViewDetail: executiveViewDetail,
  BOOKNOWFORM: bookNowForm,
  LANDING_PAGE: LandingPage,
  LAB_TEST: LabTest,
  LAB_TEST_DETAIL_VIEW: LabTestDetailView,
  DOCUMENT_VIEW: DocumentView,
};

export { WEB_PAGES };

import api from "../../../assets/genericComponents/API";

const LoginUser = async (userData) => {
  const response = await api.post(`/api/account/login`, userData);
  if (response?.data?.success === true) {
    localStorage.setItem("buch_user", JSON.stringify(response?.data?.data));
  }
  return response.data;
};

const resetPassword = async (data) => {
  const response = await api.post("api/account/resetPassword", null, {
    params: data,
  });
  return response.data;
};

const patientPasswordReset = async (data) => {
  const response = await api.post(`/api/account/patientPasswordReset`, data);
  return response.data;
};

const confirmOTP = async (data) => {
  const response = await api.post("api/account/confirmOTP", null, {
    params: data,
  });
  return response.data;
};

const regenerateOTP = async (data) => {
  const response = await api.post("api/account/regenerateOTP", null, {
    params: data,
  });
  return response.data;
};

const getLastLogedIn = async (data) => {
  const response = await api.post("api/account/getLastLogedIn", null, {
    params: data,
  });
  return response.data;
};

const confirmPassword = async (data) => {
  const response = await api.post("api/account/confirmPassword", null, {
    params: data,
  });
  return response.data;
};

const RegisterServices = {
  LoginUser,
  resetPassword,
  getLastLogedIn,
  regenerateOTP,
  confirmPassword,
  confirmOTP,
  patientPasswordReset,
};

export default RegisterServices;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import MedicalHistoryServices from "./appointment.service";

const initialState = {
  getAllMedicalHistory: [],
  isError: false,
  isLoading: false,
  getAllMedicalHistoryLoading: false,
  isSuccess: false,
  message: "",
};

export const getAllMedicalHistoryAction = createAsyncThunk(
  "appointment/getAllMedicalHistoryAction",
  async (data, thunkAPI) => {
    try {
      return await MedicalHistoryServices.getAllMedicalHistory(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const appointmentSlice = createSlice({
  name: "appointment",
  initialState,
  reducers: {
    clearappointment: (state) => {
      state.getAllSpeciality = [];
      state.isLoading = false;
      state.isFailure = false;
      state.isSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(getAllMedicalHistoryAction.pending, (state) => {
        state.getAllMedicalHistoryLoading = true;
      })
      .addCase(getAllMedicalHistoryAction.fulfilled, (state, action) => {
        state.getAllMedicalHistoryLoading = false;
        state.isSuccess = true;
        state.getAllMedicalHistory = action.payload;
      })
      .addCase(getAllMedicalHistoryAction.rejected, (state, action) => {
        state.getAllMedicalHistoryLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { clearappointment } = appointmentSlice.actions;

export default appointmentSlice.reducer;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import prescriptionServices from "./prescription.service";

const initialState = {
  prescriptionData: [],
  isError: false,
  isSuccess: false,
  prescriptionDataIsLoading: false,
  message: "",

  PatientPrescriptionDetail: [],
  PatientPrescriptionDetailLoading: false,
};

export const getPatientListForPrescription = createAsyncThunk(
  "prescription/getPatientListForPrescription",
  async ({ finalData, moveToNext }, thunkAPI) => {
    try {
      const response =
        await prescriptionServices.getPatientVisitListForPrescription(
          finalData
        );
      if (moveToNext) {
        moveToNext(response?.[0]);
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getPatientPrescriptionDetailsAction = createAsyncThunk(
  "prescription/getPatientPrescriptionDetailsAction",
  async (finalData, thunkAPI) => {
    try {
      const response = await prescriptionServices.getPatientPrescriptionDetails(
        finalData
      );
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const prescriptionSlice = createSlice({
  name: "prescription",
  initialState,
  reducers: {
    clearPrescription: (state) => {
      state.prescriptionData = [];
      state.PatientPrescriptionDetail = [];
      state.isLoading = false;
      state.isFailure = false;
      state.isSuccess = false;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getPatientListForPrescription.pending, (state) => {
        state.prescriptionDataIsLoading = true;
      })
      .addCase(getPatientListForPrescription.fulfilled, (state, action) => {
        state.prescriptionDataIsLoading = false;
        state.isSuccess = true;
        state.prescriptionData = action.payload;
      })
      .addCase(getPatientListForPrescription.rejected, (state, action) => {
        state.prescriptionDataIsLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getPatientPrescriptionDetailsAction.pending, (state) => {
        state.PatientPrescriptionDetailLoading = true;
      })
      .addCase(
        getPatientPrescriptionDetailsAction.fulfilled,
        (state, action) => {
          state.PatientPrescriptionDetailLoading = false;
          state.isSuccess = true;
          state.PatientPrescriptionDetail = action.payload;
        }
      )
      .addCase(
        getPatientPrescriptionDetailsAction.rejected,
        (state, action) => {
          state.PatientPrescriptionDetailLoading = false;
          state.isError = true;
          state.message = action.payload;
        }
      );
  },
});

export const { clearPrescription } = prescriptionSlice.actions;

export default prescriptionSlice.reducer;

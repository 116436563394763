import { configureStore } from "@reduxjs/toolkit";
import registerReducer from "./features/auth/auth.slices";
import prescriptionReducer from "./features/prescription/prescription.slices";
import bookAppointmentReducer from "./features/bookAppointment/bookAppointment.slices";
import reportReducer from "./features/report/report.slices";
import billingReducer from "./features/billing/billing.slices";
import patientDocumentReducer from "./features/patientDocument/patientDocument.slices";
import dashbaordReducer from "./features/dashboard/dashboard.slices";
import AppointmentReducer from "./features/appointment/appointment.slices";
import ExecutiveFormReducer from "./features/executiveForm/executiveForm.slices";
import labTestReducer from "./features/labTest/labTest.slices";

const store = configureStore({
  reducer: {
    register: registerReducer,
    prescription: prescriptionReducer,
    bookAppointment: bookAppointmentReducer,
    Appointment: AppointmentReducer,
    ExecutiveForm: ExecutiveFormReducer,
    report: reportReducer,
    billing: billingReducer,
    patientDocument: patientDocumentReducer,
    dashboard: dashbaordReducer,
    labTest: labTestReducer,
  },
});

export { store };

import React from "react";
import { BsHouseDoor, BsJournalMedical } from "react-icons/bs";
import { TbCalendarTime, TbReportMedical } from "react-icons/tb";
import { FaFilePrescription } from "react-icons/fa";
import { AiOutlineUpload } from "react-icons/ai";
import { HiDocument } from "react-icons/hi";
import { ImLab } from "react-icons/im";
import { PATH } from "../../config";

export const SidebarData = [
  {
    title: "Dashboard",
    icon: <BsHouseDoor size={24} />,
    path: PATH.DASHBOARD,
    pathList: [PATH.DASHBOARD],
  },

  {
    title: "Appointments",
    icon: <TbCalendarTime size={24} />,
    path: PATH.APPOINTMENTS,
    pathList: [PATH.APPOINTMENTS],
  },
  {
    title: "Prescriptions",
    icon: <FaFilePrescription size={24} />,
    path: PATH.PRESCRIPTION,
    pathList: [PATH.PRESCRIPTION],
  },
  {
    title: "Reports",
    icon: <TbReportMedical size={26} />,
    path: PATH.REPORTS,
    pathList: [PATH.REPORTS],
  },
  {
    title: "Billings",
    icon: <BsJournalMedical size={21} />,
    path: PATH.BILLING,
    pathList: [PATH.BILLING],
  },
  {
    title: "LabTest",
    icon: <ImLab size={24} />,
    path: PATH.LAB_TEST,
    pathList: [PATH.LAB_TEST],
  },
  {
    title: "Documents",
    icon: <HiDocument size={24} />,
    path: PATH.DOCUMENT_VIEW,
    pathList: [PATH.DOCUMENT_VIEW],
  },

  // {
  //   title: "Multi Links",
  //   icon: "",
  //   subNav: [
  //     {
  //       title: "Tab 1",
  //       subNav: [
  //         {
  //           title: "Link 1",
  //           path: "",
  //           pathList: [],
  //           icon: <GoPrimitiveDot className="me-2 font-16" />,
  //         },
  //         {
  //           title: "Link 2",
  //           path: "",
  //           pathList: [],
  //           icon: <GoPrimitiveDot className="me-2 font-16" />,
  //         },
  //       ],
  //     },
  //   ],
  // },
];

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import billingServices from "./billing.service";

const initialState = {
  billingData: [],
  getPatientVisitTypeInvoices_V2: [],
  isError: false,
  isSuccess: false,
  billingDataLoading: false,
  getPatientVisitTypeInvoices_V2Loading: false,
  message: "",
};

export const getPatientVisitTypeInvoicesAction = createAsyncThunk(
  "billing/getPatientVisitTypeInvoicesAction",
  async ({ finalData, moveToNext }, thunkAPI) => {
    try {
      const response = await billingServices.getPatientVisitTypeInvoices(
        finalData
      );
      if (moveToNext) {
        moveToNext(response?.[0]);
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getPatientVisitTypeInvoices_V2Action = createAsyncThunk(
  "billing/getPatientVisitTypeInvoices_V2Action",
  async ({ finalData, moveToNext }, thunkAPI) => {
    try {
      const response = await billingServices.getPatientVisitTypeInvoices_V2(
        finalData
      );
      if (moveToNext) {
        
        moveToNext(response?.[0]);
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const billingSlice = createSlice({
  name: "billing",
  initialState,
  reducers: {
    clearBilling: (state) => {
      state.billingData = [];
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getPatientVisitTypeInvoicesAction.pending, (state) => {
        state.billingDataLoading = true;
      })
      .addCase(getPatientVisitTypeInvoicesAction.fulfilled, (state, action) => {
        state.billingDataLoading = false;
        state.isSuccess = true;
        state.billingData = action.payload;
      })
      .addCase(getPatientVisitTypeInvoicesAction.rejected, (state, action) => {
        state.billingDataLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getPatientVisitTypeInvoices_V2Action.pending, (state) => {
        state.getPatientVisitTypeInvoices_V2Loading = true;
      })
      .addCase(
        getPatientVisitTypeInvoices_V2Action.fulfilled,
        (state, action) => {
          state.getPatientVisitTypeInvoices_V2Loading = false;
          state.isSuccess = true;
          state.getPatientVisitTypeInvoices_V2 = action.payload;
        }
      )
      .addCase(
        getPatientVisitTypeInvoices_V2Action.rejected,
        (state, action) => {
          state.getPatientVisitTypeInvoices_V2Loading = false;
          state.isError = true;
          state.message = action.payload;
        }
      );
  },
});

export const { clearBilling } = billingSlice.actions;

export default billingSlice.reducer;

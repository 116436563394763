/* eslint-disable */
import React, { useState } from "react";
import PuffLoader from "react-spinners/PuffLoader";
import BounceLoader from "react-spinners/BounceLoader";
import BeatLoader from "react-spinners/BeatLoader";

export function LoaderCenter() {
  const override = {
    display: "block",
    margin: "0 auto",
  };
  let [loading, setLoading] = useState(true);
  return (
    <div className="text-align-center">
      <PuffLoader loading={loading} cssOverride={override} size={50} />
    </div>
  );
}

export function LoaderRight() {
  const override = {
    display: "block",
    marginLeft: "auto",
  };
  let [loading, setLoading] = useState(true);
  return <PuffLoader loading={loading} cssOverride={override} size={50} />;
}

export function LoaderLeft() {
  const override = {
    display: "block",
    marginRight: "auto",
  };
  let [loading, setLoading] = useState(true);
  return <PuffLoader loading={loading} cssOverride={override} size={50} />;
}

export function LoaderTable() {
  const override = {
    display: "block",
    margin: "0 auto",
  };
  let [loading, setLoading] = useState(true);
  return (
    <div>
      <PuffLoader loading={loading} cssOverride={override} size={40} />
    </div>
  );
}

export function Loader1() {
  const override = {
    display: "block",
    margin: "0 auto",
    color: "rgba(179, 208, 92, 1)",
  };
  let [loading, setLoading] = useState(true);
  return (
    <div className="text-align-center loader_white">
      <PuffLoader
        loading={loading}
        color={override?.color}
        cssOverride={override}
        size={50}
      />
    </div>
  );
}
export function LoaderPdf() {
  const override = {
    display: "block",
    margin: "0 auto",
    color: "rgba(179, 208, 92, 1)",
  };
  let [loading, setLoading] = useState(true);
  return (
    <div className="text-align-center loader_white ">
      <PuffLoader
        loading={loading}
        color={override?.color}
        cssOverride={override}
        size={100}
      />
    </div>
  );
}
export function LoaderInButton() {
  const override = {
    display: "block",
    margin: "0 auto",
    color: "rgba(179, 208, 92, 1)",
  };
  let [loading, setLoading] = useState(true);
  return (
    <div className="text-align-center loader_white">
      <PuffLoader loading={loading} cssOverride={override} size={25} />
    </div>
  );
}

export function BounceLoader1() {
  const override = {
    display: "block",
    margin: "0 auto",
    color: "rgba(179, 208, 92, 1)",
  };
  let [loading, setLoading] = useState(true);
  return (
    <div className="text-align-center loader_white">
      <BounceLoader
        loading={loading}
        color={override.color}
        cssOverride={override}
        size={30}
      />
    </div>
  );
}

export function BeatLoader1() {
  const override = {
    display: "block",
    margin: "0 auto",
    color: "#36d7b7",
  };
  let [loading, setLoading] = useState(true);
  return (
    <div className="text-align-center loader_white">
      <BeatLoader loading={loading} cssOverride={override} size={30} />
    </div>
  );
}

export function LoaderPageWithoutBG() {
  return (
    <div className="loader-body-without-Bg">
      <div align="center" className="fond">
        <div className="contener_general">
          <div className="contener_mixte">
            <div className="ballcolor ball_1">&nbsp;</div>
          </div>
          <div className="contener_mixte">
            <div className="ballcolor ball_2">&nbsp;</div>
          </div>
          <div className="contener_mixte">
            <div className="ballcolor ball_3">&nbsp;</div>
          </div>
          <div className="contener_mixte">
            <div className="ballcolor ball_4">&nbsp;</div>
          </div>
        </div>
      </div>
    </div>
  );
}

import ProfileLogo from "./Ellipse 9.jpg";
import buchLogo from "./buchLogo.png";
import loginLogo from "./loginLogo.png";
import greenCalendar from "./greencalender.svg";
import orangeCalendar from "./orangecalendar.svg";
import bloodTest from "./bloodTest.svg";
import ultraSound from "./ultrasound.svg";
import anesthesia from "./anesthesia.png";
import cardiology from "./cardiology.png";
import cosmeticsurgery from "./cosmetic-surgery.png";
import dermatologist from "./dermatologist.png";
import diseases from "./diseases.png";
import Endocrinology from "./Endocrinology.png";
import ent from "./ent.png";
import family from "./family.png";
import gastroenterology from "./gastroenterology.png";
import healthinsurance from "./health-insurance.png";
import hematology from "./hematology.png";
import mri from "./mri.png";
import Nephrology from "./Nephrology.png";
import neurologist from "./neurologist.png";
import nutrition from "./nutrition.png";
import Obs_Gynecology from "./Obs_Gynecology.png";
import Ophthalmology from "./hematology.png";
import orthopedics from "./orthopedics.png";
import pediatrics from "./pediatrics.png";
import Psychiatry from "./Psychiatry.png";
import Psychology from "./Psychology1.png";
import pulmonology from "./pulmonology.png";
import rheumatology from "./rheumatology.png";
import Speechthera from "./Speech-thera.png";
import surgery from "./surgery.png";
import tooth from "./tooth.png";
import urology from "./urology.png";
import Chaman from "./Dr. Chaman.png";
import ExecutiveHealthCheckups from "./ehc-img.png";
import modala from "./modela.png";
import modalb from "./modelb.png";
import modalafe from "./modelafe.png";
import modalbfe from "./modelbfe.png";
import loginLogoBannar from "./LoginLogoBannar.svg";
import logoBannar from "./LogoBannar.png";
import kingIcon from "./kingIcon.svg";

export const IMAGES = {
  GYNECOLOGY: Obs_Gynecology,
  MODELA: modala,
  MODELB: modalb,
  MODELAFE: modalafe,
  MODELBFE: modalbfe,
  DRCHAMAN: Chaman,
  NUTRITION: nutrition,
  NEUROLOGIST: neurologist,
  NEPHROLOGY: Nephrology,
  MRI: mri,
  HEMOTOLOGY: hematology,
  INSURANCE: healthinsurance,
  GESTROENTEROLOGY: gastroenterology,
  FAMAILY: family,
  ENT: ent,
  ENDOCRINOLOGY: Endocrinology,
  DISEASE: diseases,
  DEMOTOLOGIST: dermatologist,
  COSMETICSURGERY: cosmeticsurgery,
  CARDIOLOGY: cardiology,
  ANESTHESIA: anesthesia,
  UROLOGY: urology,
  TOOTH: tooth,
  SURGERY: surgery,
  SPEECHTHERA: Speechthera,
  RHEUMATOLOGY: rheumatology,
  PHULMONOLOGY: pulmonology,
  PSYCHOLOGY: Psychology,
  PSYCHIATRY: Psychiatry,
  PEDIATRICS: pediatrics,
  PRHTOPEDICS: orthopedics,
  OPHTHALMOLOGY: Ophthalmology,
  GREENCALENDAR: greenCalendar,
  BLOODTEST: bloodTest,
  ULTRASOUND: ultraSound,
  ORANGECALENDAR: orangeCalendar,
  LOGIN_LOGO: loginLogo,
  BUCHLOGO: buchLogo,
  PROFILELOGO: ProfileLogo,
  EHC_IMG: ExecutiveHealthCheckups,
  LOGIN_LOGO_BANNAR: loginLogoBannar,
  LOGO_BANNAR: logoBannar,
  KING_ICON: kingIcon,
};

import axios from "axios";
import { toast } from "react-toastify";
import { TOASTER_STYLING_VALUES } from "../../config";

const user = JSON.parse(localStorage.getItem("buch_user"));

const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

if (user) headers.Authorization = `Bearer ${user.token}`;

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers,
});

// api.interceptors.response.use(
//   (response) => response,
//   console.log("response", response)
//   (error) => {
//     if (error.response.status === 401) {
//       toast.success(
//         "Session expired, please signIn again to continue",
//         TOASTER_STYLING_VALUES
//       );
//       localStorage.removeItem("buch_user");
//       window.location.href = "/";
//     }

//     let { message } = error.response.data;

//     if (!message) {
//       message =
//         (error?.response &&
//           error?.response?.data &&
//           error?.response?.data?.message) ||
//         error?.message ||
//         error.toString();
//     }

//     const errors = {
//       errors: error?.response?.data?.errors,
//       message,
//     };

//     // toast.error(message);

//     throw errors;
//   }
// );
api.interceptors.response.use(
  (response) => {
    console.log("response", response?.status); // Log the response here
    // if (response.status === 401) {
    //   localStorage.removeItem("buch_user");
    //   window.location.href = "/"; // Redirect when status is 200
    // }
    return response; // Make sure to return the response
  },
  (error) => {
    if (error.response.status === 401) {
      toast.error(
        "Session expired, please signIn again to continue",
        TOASTER_STYLING_VALUES
      );
      setTimeout(() => {
        localStorage.removeItem("buch_user");
        window.location.href = "/";
      }, 1000);
    }

    let { message } = error.response.data;

    if (!message) {
      message =
        (error?.response &&
          error?.response?.data &&
          error?.response?.data?.message) ||
        error?.message ||
        error.toString();
    }

    const errors = {
      errors: error?.response?.data?.errors,
      message,
    };

    // toast.error(message);

    throw errors;
  }
);

export default api;

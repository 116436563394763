import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import dashboardServices from "./dashbaord.service";

const initialState = {
  getPatientAppointments: [],
  getPatientUpcomingAppointments: [],
  getLattestPatientReport: [],
  getFollowUps: [],
  isError: false,
  isSuccess: false,
  getPatientAppointmentsLoading: false,
  getPatientUpcomingAppointmentsLoading: false,
  getLattestPatientReportLoading: false,

  getFollowUpsLoading: false,
  message: "",
};

export const getLatestPatientPrescriptionAction = createAsyncThunk(
  "dashboard/getLatestPatientPrescriptionAction",
  async (finalData, thunkAPI) => {
    try {
      const response = await dashboardServices.getLatestPatientPrescription(
        finalData
      );
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getPatientAppointmentsAction = createAsyncThunk(
  "dashboard/getPatientAppointmentsAction",
  async (finalData, thunkAPI) => {
    try {
      const response = await dashboardServices.getPatientAppointments(
        finalData
      );
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getPatientUpcomingAppointmentsAction = createAsyncThunk(
  "dashboard/getPatientUpcomingAppointmentsAction",
  async (finalData, thunkAPI) => {
    try {
      const response = await dashboardServices.getPatientUpcomingAppointments(
        finalData
      );
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getLatestPatientReportsAction = createAsyncThunk(
  "dashboard/getLatestPatientReports",
  async (finalData, thunkAPI) => {
    try {
      const response = await dashboardServices.getLatestPatientReports(
        finalData
      );
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getFollowUpsAction = createAsyncThunk(
  "dashboard/getFollowUpsAction",
  async (finalData, thunkAPI) => {
    try {
      const response = await dashboardServices.getPatientFollowupAppointments(
        finalData
      );
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    cleardashboard: (state) => {
      state.getLattestPrescription = [];
      state.isLoading = false;
      state.isFailure = false;
      state.isSuccess = false;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getLatestPatientPrescriptionAction.pending, (state) => {
        state.getLattestPrescriptionLoading = true;
      })
      .addCase(
        getLatestPatientPrescriptionAction.fulfilled,
        (state, action) => {
          state.getLattestPrescriptionLoading = false;
          state.isSuccess = true;
          state.getLattestPrescription = action?.payload?.data;
        }
      )
      .addCase(getLatestPatientPrescriptionAction.rejected, (state, action) => {
        state.getLattestPrescriptionLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getPatientAppointmentsAction.pending, (state) => {
        state.getPatientAppointmentsLoading = true;
      })
      .addCase(getPatientAppointmentsAction.fulfilled, (state, action) => {
        state.getPatientAppointmentsLoading = false;
        state.isSuccess = true;
        state.getPatientAppointments = action.payload;
      })
      .addCase(getPatientAppointmentsAction.rejected, (state, action) => {
        state.getPatientAppointmentsLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getPatientUpcomingAppointmentsAction.pending, (state) => {
        state.getPatientUpcomingAppointmentsLoading = true;
      })
      .addCase(
        getPatientUpcomingAppointmentsAction.fulfilled,
        (state, action) => {
          state.getPatientUpcomingAppointmentsLoading = false;
          state.isSuccess = true;
          state.getPatientUpcomingAppointments = action.payload;
        }
      )
      .addCase(
        getPatientUpcomingAppointmentsAction.rejected,
        (state, action) => {
          state.getPatientUpcomingAppointmentsLoading = false;
          state.isError = true;
          state.message = action.payload;
        }
      )

      .addCase(getLatestPatientReportsAction.pending, (state) => {
        state.getLattestPatientReportLoading = true;
      })
      .addCase(getLatestPatientReportsAction.fulfilled, (state, action) => {
        state.getLattestPatientReportLoading = false;
        state.isSuccess = true;
        state.getLattestPatientReport = action.payload;
      })
      .addCase(getLatestPatientReportsAction.rejected, (state, action) => {
        state.getLattestPatientReportLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getFollowUpsAction.pending, (state) => {
        state.getFollowUpsLoading = true;
      })
      .addCase(getFollowUpsAction.fulfilled, (state, action) => {
        state.getFollowUpsLoading = false;
        state.isSuccess = true;
        state.getFollowUps = action.payload;
      })
      .addCase(getFollowUpsAction.rejected, (state, action) => {
        state.getFollowUpsLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { cleardashboard } = dashboardSlice.actions;

export default dashboardSlice.reducer;

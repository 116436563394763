import api from "../../../assets/genericComponents/API";

const getLatestPatientPrescription = async (data) => {
  const response = await api.get(
    "/api/prescription/getLatestPatientPrescription",
    {
      params: data,
    }
  );
  return response.data;
};

const getPatientAppointments = async (data) => {
  const response = await api.get("/api/appointment/getPatientAppointments", {
    params: data,
  });
  return response.data.data;
};

const getPatientFollowupAppointments   = async (data) => {
  const response = await api.get(
    "/api/appointment/getPatientFollowupAppointments",
    {
      params: data,
    }
  );
  return response.data.data;
};

const getPatientUpcomingAppointments = async (data) => {
  const response = await api.get(
    "/api/appointment/getUpcomingPatientAppointments",
    {
      params: data,
    }
  );
  return response.data.data;
};

const getLatestPatientReports = async (data) => {
  const response = await api.get("/api/laboratory/getLatestPatientReports", {
    params: data,
  });
  return response.data.data;
};

const dashboardServices = {
  getLatestPatientPrescription,
  getPatientAppointments,
  getPatientUpcomingAppointments,
  getPatientFollowupAppointments,
  getLatestPatientReports,
};

export default dashboardServices;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import reportServices from "./report.service";

const initialState = {
  reportsData: [],
  isError: false,
  isSuccess: false,
  reportsDataLoading: false,
  message: "",
  PatientMedicalReportDetail: [],
  PatientMedicalReportDetailLoading: false,
  getAllPatientVisit: [],
  getAllPatientVisitLoading: false,
};

export const getPatientMedicalReportListAction = createAsyncThunk(
  "report/getPatientMedicalReportListAction",
  async (finalData, thunkAPI) => {
    try {
      const response = await reportServices.getPatientMedicalReportList(
        finalData
      );
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getPatientMedicalReportDetailAction = createAsyncThunk(
  "report/getPatientMedicalReportDetailAction",
  async (finalData, thunkAPI) => {
    try {
      const response = await reportServices.getPatientMedicalReportDetail(
        finalData
      );
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllPatientVisitAction = createAsyncThunk(
  "report/getAllPatientVisitAction",
  async ({ finalData, moveToNext }, thunkAPI) => {
    try {
      const response = await reportServices.getAllPatientVisit(finalData);
      if (moveToNext) {
        moveToNext(response?.[0]);
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    clearReports: (state) => {
      state.reportsData = [];
      state.PatientMedicalReportDetail = [];
      state.isLoading = false;
      state.isFailure = false;
      state.isSuccess = false;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getPatientMedicalReportListAction.pending, (state) => {
        state.reportsDataLoading = true;
      })
      .addCase(getPatientMedicalReportListAction.fulfilled, (state, action) => {
        state.reportsDataLoading = false;
        state.isSuccess = true;
        state.reportsData = action.payload;
      })
      .addCase(getPatientMedicalReportListAction.rejected, (state, action) => {
        state.reportsDataLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getPatientMedicalReportDetailAction.pending, (state) => {
        state.PatientMedicalReportDetailLoading = true;
      })
      .addCase(
        getPatientMedicalReportDetailAction.fulfilled,
        (state, action) => {
          state.PatientMedicalReportDetailLoading = false;
          state.isSuccess = true;
          state.PatientMedicalReportDetail = action.payload;
        }
      )
      .addCase(
        getPatientMedicalReportDetailAction.rejected,
        (state, action) => {
          state.PatientMedicalReportDetailLoading = false;
          state.isError = true;
          state.message = action.payload;
        }
      )
      .addCase(getAllPatientVisitAction.pending, (state) => {
        state.getAllPatientVisitLoading = true;
      })
      .addCase(getAllPatientVisitAction.fulfilled, (state, action) => {
        state.getAllPatientVisitLoading = false;
        state.isSuccess = true;
        state.getAllPatientVisit = action.payload;
      })
      .addCase(getAllPatientVisitAction.rejected, (state, action) => {
        state.getAllPatientVisitLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { clearReports } = reportSlice.actions;

export default reportSlice.reducer;

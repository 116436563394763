import api from "../../../assets/genericComponents/API";

const getPatientMedicalReportList = async (data) => {
  const response = await api.get("/api/report/getPatientMedicalReportList", {
    params: data,
  });
  return response.data.data;
};

const getPatientMedicalReportDetail = async (data) => {
  const response = await api.get("/api/report/getPatientMedicalReportDetail", {
    params: data,
  });
  return response.data.data;
};
const getAllPatientVisit = async (data) => {
  const response = await api.get("/api/laboratory/getAllPatientVisit", {
    params: data,
  });
  return response.data.data;
};

const reportServices = {
  getPatientMedicalReportList,
  getPatientMedicalReportDetail,
  getAllPatientVisit,
};

export default reportServices;

import api from "../../../assets/genericComponents/API";

const executiveForm = async (data) => {
  const response = await api.post(
    `https://buchhospital.com/ppapi/pp_exhealth.php`,
    data
  );
  return response.data;
};

const executiveFormServices = {
  executiveForm,
};

export default executiveFormServices;

/* eslint-disable */
import React, { useEffect, useState } from "react";
import { FaBars } from "react-icons/fa";
import {
  Button,
  ButtonToolbar,
  Dropdown,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { SignOut } from "./SignOut";
import { IMAGES } from "../images";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { PATH } from "../../config";
import { BsChevronBarDown, BsChevronDown } from "react-icons/bs";
import { convertBase64, createImageFromInitials } from "../genericAction";
import { ToastContainer } from "react-toastify";
import { IoPersonSharp } from "react-icons/io5";
import { RiArrowDropDownLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { getLastLogedInAction } from "../../app/features/auth/auth.slices";
import { LoaderCenter, LoaderInButton } from "./Loader";
import { AiOutlineCloudUpload } from "react-icons/ai";
import axios from "axios";
import $ from "jquery";
import { FiLogOut } from "react-icons/fi";

const moment = require("moment");

export function Header() {
  const auth = JSON.parse(localStorage.getItem("buch_user"));
  const [ageInYearsFormat, setAgeInYearsFormat] = useState();
  const [lastLogin, setLastLogin] = useState();
  const [urlFile, setUrlFile] = useState(null);
  let history = useNavigate();
  let dispatch = useDispatch();
  let location = useLocation();

  document.body.style.backgroundColor = "#f5f5f5";
  // if (auth?.age === null) {
  // } else {
  //   [years, months, days] = auth.age.split(" ");
  //   const ageInYears = parseInt(years, 10);
  //   setAgeInYearsFormat(ageInYears);
  // }

  console.log("userProfile From auth", auth);
  let file = auth?.patientPhoto;

  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [updateImageUrl, setUpdateImageUrl] = useState(null);
  const { getLastLogedIn } = useSelector((state) => state.register);
  const [imageUrl, setImageUrl] = useState(null);
  const [imageUploadLoader, setImageUploadLoader] = useState(null);

  console.log("imageUrl", imageUrl);

  useEffect(() => {
    const convertBase64ToUrl = (file) => {
      return `data:image/png;base64,${file}`;
    };

    const url = convertBase64ToUrl(file);
    setImageUrl(url);

    setUpdateImageUrl(auth.patientphoto);

    let finalData = {
      patientPIN: auth?.pin,
    };
    dispatch(
      getLastLogedInAction({
        data: finalData,
        moveToNext: moveToNext,
      })
    );
  }, [file, getLastLogedIn]);

  const utcLastLogin = lastLogin;

  // Parse the UTC date using the specified format
  const parsedUtcDate = moment.utc(utcLastLogin, "MM/DD/YYYY h:mm:ss A");

  // Convert to local time
  const localDate = parsedUtcDate.local();

  // Format the local date as a string
  const localDateString = parsedUtcDate.isValid()
    ? parsedUtcDate.local().format("MM-DD-YYYY h:mm A")
    : "N/A";

  function moveToNext(data) {
    console.log("imageBase64", data);
    setLastLogin(data?.lastLogin);
  }

  // useEffect(() => {
  //   if (auth) {
  //     const fullName = auth?.patientName;
  //     const nameParts = fullName.split(" ");

  //     const firstCharacterOfFirstName = nameParts[0][0];
  //     const firstCharacterOfLastName = nameParts[1][0];

  //     setFirstName(firstCharacterOfFirstName);
  //     setLastName(firstCharacterOfLastName);
  //   }
  // }, [auth]);

  useEffect(() => {
    if (auth) {
      const fullName = auth?.patientName;
      const nameParts = fullName.split(" ");

      if (nameParts.length >= 2) {
        const firstCharacterOfFirstName = nameParts[0][0];
        const firstCharacterOfLastName = nameParts[1][0];

        setFirstName(firstCharacterOfFirstName);
        setLastName(firstCharacterOfLastName);
      } else if (nameParts.length === 1) {
        setFirstName(nameParts[0][0]);
        setLastName("");
      } else {
      }
    }
  }, [auth]);

  useEffect(() => {
    console.log("urlFile (in useEffect):", urlFile);
    if (urlFile) {
      handleUpload();
    }
  }, [urlFile]);

  const handleFileChange = async (event) => {
    setImageUploadLoader(true);
    try {
      const selectedFile = event.target.files[0];
      console.log("Selected File:", selectedFile);
      setUrlFile(selectedFile); // This updates the state asynchronously.
    } catch (error) {
      console.error("Error handling file change:", error);
    }
  };

  useEffect(() => {
    var $wrapper = $(".main-wrapper");

    $wrapper.removeClass("slide-nav");
    $(".sidebar-overlay").removeClass("opened");
    $("html").removeClass("menu-opened");
    $("#task_window").removeClass("opened");
  }, [location]);

  console.log("userImageUpload", imageUrl);

  const handleUpload = async () => {
    try {
      console.log("urlFile", urlFile);
      if (urlFile) {
        const formData = new FormData();
        formData.append("photo", urlFile);

        const PIN = auth?.pin;

        // const url = `https://api.buchhospital.com/api/patient/uploadPatientPhoto?PIN=${PIN}`;

        const url = `https://buchpatientapi.xeventechnologies.com/api/patient/uploadPatientPhoto?PIN=${PIN}`;

        const response = await axios.post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + auth?.token,
            // You can add other headers if needed
          },
        });

        if (response.data?.data) {
          // Check if userData exists in localStorage
          const userData = JSON.parse(localStorage.getItem("buch_user"));

          if (userData) {
            userData.patientphoto = response.data.data;

            console.log("Updated userData", userData);

            localStorage.setItem("buch_user", JSON.stringify(userData));
          }

          setUpdateImageUrl(response.data.data);

          setImageUploadLoader(false);

          notification("File uploaded successfully", "success");

          moveToNext();
          console.log("File uploaded successfully");
        } else {
          // If response.data?.data is not available, log an error
          console.error("No patient photo data in the response");
        }
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      setImageUploadLoader(false);
    }
  };

  const tooltip = <Tooltip id="tooltip">Executive Health Checkup</Tooltip>;

  return (
    <>
      <ToastContainer />
      <div className="header">
        {/* Logo  */}
        <div>
          <div className="header-left">
            <Link to="#" className="logo">
              <img
                src={IMAGES.BUCHLOGO}
                alt="Logo"
                className="img-fluid monu_logo"
              />
            </Link>
          </div>
          <div className="PageName">
            {/* <a href="javascript:void(0)" id="toggle_btn">
            <FaBars className="toggleset" />
          </a> */}
            <>
              <span>
                {/* <h3 className="ml-4 mb-3">{`patient Portal`}</h3> */}
                <span className="user-img mt-0 d-flex justify-content-start">
                  {imageUrl !== "data:image/png;base64,null" ||
                  updateImageUrl ? (
                    imageUploadLoader ? (
                      <LoaderInButton />
                    ) : (
                      <img
                        src={updateImageUrl ? updateImageUrl : imageUrl}
                        className="rounded-circle "
                        width="31"
                        alt="Base64 Image"
                      />
                    )
                  ) : (
                    <>
                      {imageUploadLoader ? (
                        <LoaderInButton />
                      ) : (
                        <>
                          <span className=" first-last-name-style">
                            {firstName} {lastName}
                          </span>
                        </>
                      )}
                    </>
                  )}
                  {!imageUploadLoader && (
                    <span className="upload-image-body">
                      <AiOutlineCloudUpload className="upload-icon-styling" />
                    </span>
                  )}
                  <Form.Control
                    onChange={handleFileChange}
                    multiple
                    type="file"
                    height="100%"
                    className="form-control upload-image-aws-style"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: "55px",
                      width: "5%",
                      height: "100%",
                      opacity: 0,
                      zIndex: 2, // Add a higher z-index to bring the file input above the icon
                    }}
                    name="file"
                    accept="image/*"
                  />
                  <p className="header-profile-section-mob">{auth?.pin}</p>
                  <span className="image-side-text">
                    {auth?.patientName}
                    <span className="blood-group-style">
                      {auth?.bloodGroup || "N/A"}
                    </span>
                    {/* {location.pathname !== PATH.RESET_PASSWORD && ( */}
                    {/* <span
                      className=" cursor-pointer reset-password-color f-13 fw-semibold"
                      onClick={() => {
                        history(PATH.RESET_PASSWORD);
                      }}
                    >
                      Reset Password
                    </span> */}
                    {/* // )} */}
                    <br />
                    <p className="header-profile-section-main mb-0">
                      {auth?.pin} | {auth?.age || "N/A"} | {auth?.gender}
                    </p>
                    <p className="header-profile-section-main mb-0">
                      BMI: {auth?.vitals?.bloodMassIndex || "_"}, BP:{" "}
                      {auth?.vitals?.bloodPressure || "_"}, Wt:{" "}
                      {auth?.vitals?.weight || "_"}, Ht:{" "}
                      {auth?.vitals?.height || "_"}
                    </p>
                  </span>
                </span>
              </span>
            </>
          </div>
        </div>
        {/* /Logo */}
        {/* Mobile Menu Toggle */}
        <a href="javascript:void(0)" className="mobile_btn" id="mobile_btn">
          <FaBars />
        </a>
        {/* /Mobile Menu Toggle */}
        <div className="nav user-menu d-inline me-4">
          <SignOut />
          {/* <p className="mb-0 lastlogin-style">Last Login : {lastLogin}</p> */}
          <p className="mb-0 lastlogin-style">Last Login : {localDateString}</p>
          {/* <p className="mb-0">
            hello
          </p> */}
          <span
            className=" cursor-pointer reset-password-color f-13 fw-semibold"
            onClick={() => {
              history(PATH.RESET_PASSWORD);
            }}
          >
            Reset Password
          </span>
          <div className="header-button-style">
            <OverlayTrigger placement="bottom" overlay={tooltip}>
              <Button
                onClick={() => history(PATH.EXECUTIVEHEALTHCHECKUPS)}
                variant="secondary"
                className="py-2 py-sm-2 px-sm-2 px-3"
                data-tooltip-id="my-tooltip-1"
              >
                Book <span className="ehc-text-style">EHC</span>{" "}
                <img
                  src={IMAGES.KING_ICON}
                  alt="img"
                  className="king-icon-style"
                />{" "}
                {/* (Executive Health Checkup) */}
              </Button>
            </OverlayTrigger>

            <Button
              variant="primary"
              onClick={() => history(PATH.BOOKAPPOINTMENTS)}
              className="py-2 py-sm-2 px-sm-2 px-3"
            >
              Book an Appointment
            </Button>
          </div>

          {/* <li className="nav-item dropdown has-arrow d-flex pe-0">
            <span className="user-img mt-0 d-flex justify-content-center">
              <img
                className="rounded-circle"
                src={IMAGES.PROFILELOGO}
                width="31"
                alt="Ryan Taylor"
              />
              <span className="image-side-text">Dr. Betty White</span>
            </span>

            <Dropdown className="user-dropdown h-100">
              <Dropdown.Toggle
                variant="success"
                id="dropdown-basic"
                className="user_dropdown custom-toggle"
              >
                <RiArrowDropDownLine className="text-dark ml-2" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="profile-dropmenu">
                <Dropdown.Item className="no-padding">
                  <div className="logout-text ms-4">
                    <IoPersonSharp size={19} className="me-3" />
                    <p className=" randox-text-dark mb-0 f-13">My Profile</p>
                  </div>
                </Dropdown.Item>
                <Dropdown.Item className="no-padding ">
                  <SignOut />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li> */}
        </div>
      </div>
    </>
  );
}

import api from "../../../assets/genericComponents/API";

const getPatientVisitListForPrescription = async (data) => {
  const response = await api.get(
    "/api/prescription/getPatientVisitListForPrescription",
    {
      params: data,
    }
  );
  return response.data.data;
};

const getPatientPrescriptionDetails = async (data) => {
  const response = await api.get(
    "/api/prescription/getPatientPrescriptionDetails",
    {
      params: data,
    }
  );
  return response.data.data;
};

const prescriptionServices = {
  getPatientVisitListForPrescription,
  getPatientPrescriptionDetails,
};

export default prescriptionServices;

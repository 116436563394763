import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import patientDocumentServices from "./patientDocument.service";

const initialState = {
  getDocumentType: [],
  getAllPatientDocument: [],
  ViewPatientDocument: [],
  isError: false,
  isSuccess: false,
  getDocumentTypeLoading: false,
  ViewPatientDocumentLoading: false,
  getAllPatientDocumentLoading: false,
  message: "",
  uploadDocument: [],
  uploadDocumentLoading: false,
};

export const getAllDocumentTypesAction = createAsyncThunk(
  "report/getAllDocumentTypesAction",
  async (thunkAPI) => {
    try {
      const response = await patientDocumentServices.getAllDocumentTypes();
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const ViewPatientDocumentAction = createAsyncThunk(
  "report/ViewPatientDocumentAction",
  async ({ finalData }, thunkAPI) => {
    try {
      const response = await patientDocumentServices.ViewPatientDocument(
        finalData
      );
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const uploadPatientDocumentsAction = createAsyncThunk(
  "report/uploadPatientDocumentsAction",
  async ({ finalData, notification, moveToNext }, thunkAPI) => {
    try {
      const response = await patientDocumentServices.uploadPatientDocuments(
        finalData
      );
      if (response.httpStatusCode === 200) {
        if (notification) {
          notification(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        notification(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllPatientDocumentAction = createAsyncThunk(
  "report/getAllPatientDocumentAction",
  async ({ finalData }, thunkAPI) => {
    try {
      const response = await patientDocumentServices.getAllPatientDocument(
        finalData
      );
      if (response.httpStatusCode === 200) {
      } else {
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const patientDocumentSlice = createSlice({
  name: "patientDocument",
  initialState,
  reducers: {
    clearPatientDocument: (state) => {
      state.reportsData = [];
      state.PatientMedicalReportDetail = [];
      state.isLoading = false;
      state.isFailure = false;
      state.isSuccess = false;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getAllDocumentTypesAction.pending, (state) => {
        state.getDocumentTypeLoading = true;
      })
      .addCase(getAllDocumentTypesAction.fulfilled, (state, action) => {
        state.getDocumentTypeLoading = false;
        state.isSuccess = true;
        state.getDocumentType = action.payload.data;
      })
      .addCase(getAllDocumentTypesAction.rejected, (state, action) => {
        state.getDocumentTypeLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(ViewPatientDocumentAction.pending, (state) => {
        state.ViewPatientDocumentLoading = true;
      })
      .addCase(ViewPatientDocumentAction.fulfilled, (state, action) => {
        state.ViewPatientDocumentLoading = false;
        state.isSuccess = true;
        state.ViewPatientDocument = action.payload.data;
      })
      .addCase(ViewPatientDocumentAction.rejected, (state, action) => {
        state.ViewPatientDocumentLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(uploadPatientDocumentsAction.pending, (state) => {
        state.uploadDocumentLoading = true;
      })
      .addCase(uploadPatientDocumentsAction.fulfilled, (state, action) => {
        state.uploadDocumentLoading = false;
        state.isSuccess = true;
        state.uploadDocument = action.payload;
      })
      .addCase(uploadPatientDocumentsAction.rejected, (state, action) => {
        state.uploadDocumentLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getAllPatientDocumentAction.pending, (state) => {
        state.getAllPatientDocumentLoading = true;
      })
      .addCase(getAllPatientDocumentAction.fulfilled, (state, action) => {
        state.getAllPatientDocumentLoading = false;
        state.isSuccess = true;
        state.getAllPatientDocument = action.payload;
      })
      .addCase(getAllPatientDocumentAction.rejected, (state, action) => {
        state.getAllPatientDocumentLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { clearPatientDocument } = patientDocumentSlice.actions;

export default patientDocumentSlice.reducer;

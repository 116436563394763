import api from "../../../assets/genericComponents/API";

const getAllDocumentTypes = async () => {
  const response = await api.get("/api/patient/getAllDocumentTypes");
  return response.data;
};

// const ViewPatientDocument = async (data) => {
//   const response = await api.post("/patient/ViewPatientDocument", data);
//   return response.data;
// };

const ViewPatientDocument = async (data) => {
  const response = await api.post(`/api/patient/ViewPatientDocument`, data);
  return response.data;
};

const getAllPatientDocument = async (data) => {
  const response = await api.get("/api/patient/getAllPatientDocument", {
    params: data,
  });
  return response.data;
};

const uploadPatientDocuments = async (data) => {
  const response = await api.post(
    `/api/patient/uploadPatientDocuments?PIN=BIH0129292&DocumentType=5915&CreatedBy=BIH0129292`,
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

const patientDocumentServices = {
  getAllDocumentTypes,
  uploadPatientDocuments,
  getAllPatientDocument,
  ViewPatientDocument,
};

export default patientDocumentServices;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import RegisterServices from "./auth.service";

const initialState = {
  loginAccount: null,
  resetPassword: null,
  patientPasswordReset: null,
  getLastLogedIn: [],
  confirmOTP: null,
  regenerateOTP: null,
  confirmPassword: null,
  isError: false,
  isSuccess: false,
  notification: null,
  moveToNext: false,
  isLoading: false,
  getLastLogedInisLoading: false,
  confirmPasswordisLoading: false,
  regenerateOTPisLoading: false,
  confirmOTPisLoading: false,
  resetPasswordisLoading: false,
  patientPasswordResetLoading: false,
  message: "",
};

export const loginAction = createAsyncThunk(
  "register/loginAction",
  async ({ finalData, moveToNext, Notification }, thunkAPI) => {
    try {
      const response = await RegisterServices.LoginUser(finalData);
      debugger;
      if (response.success === true) {
        if (moveToNext) {
          moveToNext();
        }
      } else {
        Notification(response.message, "error");
      }
      return response.data;
    } catch (error) {
      // notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const resetPasswordAction = createAsyncThunk(
  "register/resetPasswordAction",
  async (payload, thunkAPI) => {
    const { data, Notification, movetoNext } = payload;
    try {
      const response = await RegisterServices.resetPassword(data);
      if (response.httpStatusCode === 200) {
        if (Notification) {
          Notification(response.message, "success");
        }
        if (movetoNext) {
          movetoNext();
        }
      } else {
        Notification(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const patientPasswordResetAction = createAsyncThunk(
  "register/patientPasswordResetAction",
  async (payload, thunkAPI) => {
    const { data, Notification, movetoNext } = payload;
    try {
      const response = await RegisterServices.patientPasswordReset(data);
      debugger;
      if (response.httpStatusCode === 200) {
        if (Notification) {
          Notification(response.message, "success");
        }
        if (movetoNext) {
          movetoNext(response?.data);
        }
      } else {
        Notification(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const confirmOTPAction = createAsyncThunk(
  "register/confirmOTPAction",
  async (payload, thunkAPI) => {
    const { data, Notification, movetoNext } = payload;
    try {
      const response = await RegisterServices.confirmOTP(data);
      if (response.httpStatusCode === 200) {
        if (Notification) {
          Notification(response.message, "success");
        }
        if (movetoNext) {
          movetoNext();
        }
      } else {
        Notification(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const regenerateOTPAction = createAsyncThunk(
  "register/regenerateOTPAction",
  async (payload, thunkAPI) => {
    const { data, Notification } = payload;
    try {
      const response = await RegisterServices.regenerateOTP(data);
      if (response.httpStatusCode === 200) {
        if (Notification) {
          Notification(response.message, "success");
        }
      } else {
        Notification(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const confirmPasswordAction = createAsyncThunk(
  "register/confirmPasswordAction",
  async (payload, thunkAPI) => {
    const { data, Notification, movetoNext } = payload;
    try {
      const response = await RegisterServices.confirmPassword(data);
      debugger;
      if (response.httpStatusCode === 200) {
        if (Notification) {
          Notification(response.message, "success");
        }
        if (movetoNext) {
          movetoNext(response.data);
        }
      } else {
        Notification(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getLastLogedInAction = createAsyncThunk(
  "register/getLastLogedInAction",
  async (payload, thunkAPI) => {
    const { data, moveToNext } = payload;
    try {
      const response = await RegisterServices.getLastLogedIn(data);
      if (response.httpStatusCode === 200) {
        if (moveToNext) {
          moveToNext(response?.data);
        }
      } else {
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const registerSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    clearSignUp: (state) => {
      state.registerAccount = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loginAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.loginAccount = action.payload;
      })
      .addCase(loginAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(resetPasswordAction.pending, (state) => {
        state.resetPasswordisLoading = true;
      })
      .addCase(resetPasswordAction.fulfilled, (state, action) => {
        state.resetPasswordisLoading = false;
        state.isSuccess = true;
        state.resetPasswordAccount = action.payload;
      })
      .addCase(resetPasswordAction.rejected, (state, action) => {
        state.resetPasswordisLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(patientPasswordResetAction.pending, (state) => {
        state.patientPasswordResetLoading = true;
      })
      .addCase(patientPasswordResetAction.fulfilled, (state, action) => {
        state.patientPasswordResetLoading = false;
        state.isSuccess = true;
        state.patientPasswordResetAccount = action.payload;
      })
      .addCase(patientPasswordResetAction.rejected, (state, action) => {
        state.patientPasswordResetLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(confirmOTPAction.pending, (state) => {
        state.confirmOTPisLoading = true;
      })
      .addCase(confirmOTPAction.fulfilled, (state, action) => {
        state.confirmOTPisLoading = false;
        state.isSuccess = true;
        state.confirmOTPAccount = action.payload;
      })
      .addCase(confirmOTPAction.rejected, (state, action) => {
        state.confirmOTPisLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(regenerateOTPAction.pending, (state) => {
        state.regenerateOTPisLoading = true;
      })
      .addCase(regenerateOTPAction.fulfilled, (state, action) => {
        state.regenerateOTPisLoading = false;
        state.isSuccess = true;
        state.regenerateOTPAccount = action.payload;
      })
      .addCase(regenerateOTPAction.rejected, (state, action) => {
        state.regenerateOTPisLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getLastLogedInAction.pending, (state) => {
        state.getLastLogedInisLoading = true;
      })
      .addCase(getLastLogedInAction.fulfilled, (state, action) => {
        state.getLastLogedInisLoading = false;
        state.isSuccess = true;
        state.getLastLogedInAccount = action.payload;
      })
      .addCase(getLastLogedInAction.rejected, (state, action) => {
        state.getLastLogedInisLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(confirmPasswordAction.pending, (state) => {
        state.confirmPasswordisLoading = true;
      })
      .addCase(confirmPasswordAction.fulfilled, (state, action) => {
        state.confirmPasswordisLoading = false;
        state.isSuccess = true;
        state.confirmPassword = action.payload;
      })
      .addCase(confirmPasswordAction.rejected, (state, action) => {
        state.confirmPasswordisLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { clearSignUp } = registerSlice.actions;

export default registerSlice.reducer;

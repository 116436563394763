import React from "react";
import { FiLogOut } from "react-icons/fi";
export function SignOut() {
  const Swal = require("sweetalert2");
  function logout() {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Log Out!",
      showCancelButton: true,
      confirmButtonColor: "#b3d05c",
      cancelButtonColor: "rgb(36 35 35)",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("buch_user");
        window.location.href = "/";
      }
    });
  }

  return (
    <>
      <span
        className="logout-text float-end d-flex ms-4 cursor-pointer"
        onClick={() => {
          logout();
        }}
      >
        <FiLogOut size={19} className="me-2" />
        <p className=" randox-text-dark mb-0 f-13 fw-semibold">Log out</p>
      </span>
    </>
  );
}
